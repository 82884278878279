<template>
    <div>
        <el-form :inline="true" :model="searchform" class="demo-form-inline" >
            <el-form-item label="关键词">
                <el-input v-model="searchform.keyword" placeholder="请输入关键词"  style="width:260px;"></el-input>
            </el-form-item>
            <el-form-item label="投递企业">
                <el-input v-model="searchform.companyName" placeholder="请输入要搜索的企业"  style="width:260px;"></el-input>
            </el-form-item>
            <el-form-item label="行业">
                <el-cascader
                    style="width:150px;"
                    v-model="searchform.industry"
                    placeholder="请选择职位"
                    :options="jobList"
                    :props="jobProps"
                    clearable>
                </el-cascader>
            </el-form-item>
            <el-form-item label="年龄">
                <el-select v-model="searchform.age" placeholder="年龄"  style="width:150px;">
                    <el-option value="" label="不限">不限</el-option>
                    <el-option value="16-20" label="16-20岁">16-20岁</el-option>
                    <el-option value="21-30" label="21-30岁">21-30岁</el-option>
                    <el-option value="31-40" label="31-40岁">31-40岁</el-option>
                    <el-option value="41-50" label="41-50岁">41-50岁</el-option>
                    <el-option value="50-100" label="50岁以上">50岁以上</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="性别">
                <el-select v-model="searchform.gender" placeholder="性别"  style="width:150px;">
                    <el-option value="" label="不限">不限</el-option>
                    <el-option value="1" label="男">男</el-option>
                    <el-option value="2" label="女">女</el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item label="学历">
                <el-select v-model="searchform.education" placeholder="学历" style="width:150px;">
                    <el-option value="1" label="初中及以下">初中及以下</el-option>
                    <el-option value="2" label="高中">高中</el-option>
                    <el-option value="3" label="中专/中技">中专/中技</el-option>
                    <el-option value="4" label="大专">大专</el-option>
                    <el-option value="5" label="本科">本科</el-option>
                    <el-option value="6" label="硕士">硕士</el-option>
                    <el-option value="7" label="MBA/EMBA">MBA/EMBA</el-option>
                    <el-option value="8" label="博士">博士</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="期望薪资">
                <el-select v-model="searchform.salary" placeholder="期望薪资"  style="width:150px;">
                    <el-option value="" label="不限">不限</el-option>
                    <el-option value="1" label="1000-2000元">1000-2000元</el-option>
                    <el-option value="2" label="2000-3000元">2000-3000元</el-option>
                    <el-option value="3" label="3000-5000元">3000-5000元</el-option>
                    <el-option value="4" label="5000-8000元">5000-8000元</el-option>
                    <el-option value="5" label="8000-12000元">8000-12000元</el-option>
                    <el-option value="6" label="12000-15000元">12000-15000元</el-option>
                    <el-option value="7" label="15000-20000元">15000-20000元</el-option>
                    <el-option value="8" label="20000元以上">20000元以上</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="工作经验">
                <el-select v-model="searchform.hands" placeholder="工作经验" style="width:150px;">
                    <el-option value="" label="不限">不限</el-option>
                    <el-option value="1" label="无经验">无经验</el-option>
                    <el-option value="2" label="应届生">应届生</el-option>
                    <el-option value="3" label="1年以下">1年以下</el-option>
                    <el-option value="4" label="1-2年">1-2年</el-option>
                    <el-option value="5" label="2-3年">2-3年</el-option>
                    <el-option value="6" label="3-5年">3-5年</el-option>
                    <el-option value="7" label="5-8年">5-8年</el-option>
                    <el-option value="8" label="8-10年">8-10年</el-option>
                    <el-option value="9" label="10年以上">10年以上</el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间">
                <el-date-picker
                v-model="value1"
                type="daterange"
                clearable
                value-format="yyyy-MM-dd"
                range-separator="至"
                @change="changeDate"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="">
                <el-checkbox v-model="searchform.authentication">认证</el-checkbox>
                <el-checkbox v-model="searchform.works">有作品</el-checkbox>
            </el-form-item> -->
            <el-form-item style="margin-left:15px;">
                <el-button type="primary" icon="el-icon-search" @click="onSubmit" >搜索</el-button>
            </el-form-item>
            <span class="clearspan" @click="cleartj">清空搜索条件</span>
            
        </el-form>
    </div>
</template>
<script>
import apiurl from '@/api/resume'
import _api from '@/api/index'
export default {
    data(){
        return{
            value1:[],
            jobProps: {
                expandTrigger: 'hover',
                label: 'name',
                value: 'name',
                children: 'children'
            },
            searchform:{
                keyword:'',
                industry:"",//行业
                companyId:"",//行业
                age:"",
                gender:"",
                education:"",
                salary:"",
                hands:"",
                updatetime:"1",
                authentication:false,
                works:false,
                options:[],
                startTime:null,
                endTime:null,
            },
            companylist:[],
            industrylist:[],
            jobList:[],
            loading:false,
        }
    },
    created(){
        // this.getIndustry()
        this.queryIndustry()
    },
    methods:{
        changeDate(){
            if(this.value1 && this.value1.length>0){
                this.searchform.startTime=this.value1[0]
                this.searchform.endTime=this.value1[1]
            }else{
                this.searchform.startTime=null
                this.searchform.endTime=null
            }
        },
        remoteMethod(query) {
        if (query !== '') {
          this.loading = true;
          _api.get(apiurl.queryMemberNotPage,{name:query}).then(res=>{
                this.loading = false;
                this.options = res.data.filter(item => {
                    return item.userName.toLowerCase()
                    .indexOf(query.toLowerCase()) > -1;
                });
            })
        } else {
          this.options = [];
        }
      },
        queryIndustry() {
            _api.get(apiurl.queryIndustry,{}).then(res=>{
                // console.log(res)
                this.jobList=res.data
            })
        },
        cleartj(){//清空条件
            this.value1=[]
            this.searchform={
                keyword:'',
                industry:"",//行业
                age:"",
                gender:"",
                education:"",
                salary:"",
                hands:"",
                updatetime:"1",
                authentication:false,
                works:false,
                startTime:null,
                endTime:null,
            }
        },
        onSubmit(){
            // this.getCompany()
            if(this.searchform.industry!=null && this.searchform.industry!=""){
                this.searchform.industry=this.searchform.industry.join(",")
            }
            this.$emit("getSearch",this.searchform)
        },
        getCompany(){
            _api.get(apiurl.queryMemberNotPage,{name:this.searchform.companyName}).then(res=>{
                // console.log("获取到的企业id",res.data)
                // this.companylist=res.data
            })
        },
        getIndustry(){//获取行业列表
            _api.get(apiurl.industry,{}).then(res=>{
                // console.log(res)
                if(res.success){
                    this.industrylist=res.data
                }
            })
        }
    }
}
</script>