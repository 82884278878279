<template>
    <div>

        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{pagetitle}}</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button :loading="exporting" @click="showexport=true" style="margin-left:20px; float:right; margin-top:-20px;" type="primary"  icon="el-icon-bottom">导出简历</el-button>
        </div>
        <div class="box">
            <div class="search">
                <Search @getSearch="getsearchdata" />
            </div>
            <div class="resumelist" v-loading="loading">
                <ul>
                    <li v-for="(item) in queryAllResumeList" :key="item.userId">
                        <el-avatar :size="size" :src="item.picUrl?item.picUrl:'../../../assets/img/company/avata.png'"></el-avatar>
                        <div class="user-info">
<!--
                                <div class="resume">
                                    <div class="done flex">
                                        简历完成度：{{item.complete}}%
                                        <div class="progress">
                                            <div class="progress-do" :style="{'width': percentage}"></div>
                                        </div>
                                    </div>
                                    <div class="name">{{item.name}}  ({{item.gender}} , {{item.birthday}})</div>
                                    <div class="hope-job">
                                        <p>期盼职位：{{item.expectPost}}</p>
                                        <p>期望薪资：{{item.expectSalary}}</p>
                                        <p>最高学历：{{item.eduction}}</p>
                                    </div>

                                </div> -->
                                <div class="user-info-wage" slot="reference">
                                    <p class="name">{{item.name}} {{item.gender}} · {{item.birthday}} · {{item.eduction}} </p>
                                     <div class="tags-warpper" >
                                        <!-- <div class="tags">
                                            <el-tag type="primary" class="tagout" size="mini" v-for="(row, index) in item.tags" :key="`tag${index}`">{{row}}</el-tag>
                                        </div>
                                        <div class="no-tag"></div> -->
                                        <div class="newresume">
                                        <div class="done" style="font-size:12px;">
                                            简历完成度：
                                            <div class="progress" style="width:260px;">
                                                <el-progress :percentage="item.complete"></el-progress>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                        <div class="position" style="width:28%;">
                            <div class="position-name" style="margin-top:0;">
                                {{item.industry}} |
                                <span class="position-name-desc">{{item.workExperience}}</span>
                            </div>
                            <div class="position-time">
                                求职意向:
                                <span class="position-time-desc">{{item.expectPost}}</span>
                            </div>
                            <div class="position-time">
                                最高学历:
                                <span class="position-time-desc">{{item.education}}</span>
                            </div>
                        </div>
                        <div class="wage" style="width:12%;">
                            <div class="wage-info">期望薪资</div>
                            <div class="wage-number">{{item.expectSalary}}</div>
                            <div style="font-size:12px; color:#666; margin-top:5px;">
                                创建时间：{{item.createTime}}
                            </div>
                        </div>
                        <div class="download">
                            <template v-if="usertype!=3">
                            <div class="success button border mybotton" @click="routeToresumeInfo(item)" >查看简历</div>
                            <!-- <div class="active button border">下载简历</div> -->
                            <a :href="item.enclosure" target="_blank" style="display:inline-block;padding:8px 12px; margin-left:8px;" class="active button border mybotton">下载简历</a>
                            <!-- <div class="collection button border">收藏简历</div> -->
                            </template>
                        </div>
                    </li>

                </ul>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>

        <el-dialog
      title="导出"
      :visible.sync="showexport"
      width="500px"
    >
      <div>
        <div style="margin-bottom:10px;">选择导出时间段：</div>
        <el-date-picker
        v-model="exportdata"
        type="daterange"
        range-separator="至"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        :picker-options="pickerOptions"
        end-placeholder="结束日期">
      </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeexport">取 消</el-button>
        <el-button type="primary" @click="putexport">确 定</el-button>
      </span>
    </el-dialog>

    </div>
</template>
<script>
import Search from "./Search.vue"
import apiurl from '@/api/resume'
import _api from '@/api/index'
import axios from 'axios';
export default {
    data(){
        return{
            exporting:false,
             pickerOptions: {
                disabledDate(v) {
                return v.getTime() < new Date("2019-01-01").getTime() - 86400000;
                }
            },
            exportdata:null,
            showexport:false,
            usertype:1,
            pagetitle:"普通人才库",
            loading:false,
            total:0,
            options:[],
            pageSize:10,
            pageNo:1,
            percentage:40,
            size:50,
            squareUrl: "https://c-ssl.duitang.com/uploads/blog/202010/21/20201021000614_e286d.jpeg",
            queryAllResumeList:[],
            search:{
                keyword:'',
                industry:"",//行业
                companyId:"",//行业
                age:"",
                gender:"",
                education:"",
                salary:"",
                hands:"",
                updatetime:"1",
                authentication:false,
                works:false,
                ageStart:"",
                ageEnd:"",
                startTime:null,
                endTime:null
            }
        }
    },
    components:{
        Search,
    },
    watch: {
	  '$route' (to, from) { //监听路由是否变化
		  if(to.query.type != from.query.type){
              // console.log(to.query.type)
              this.usertype = to.query.type
              if(this.usertype==1){
                  this.pagetitle="普通人才库"
              }
              if(this.usertype==2){
                  this.pagetitle="高级人才库"
              }
              if(this.usertype==3){
                  this.pagetitle="零时工"
              }

              //this.init();//重新加载数据
              this.getList()
		  }
	  }
    },
    created(){
        //this.usertype=this.$route.query.type
        var url=location.href
        // console.log(url)
            var Request = new Object();
            if(url.indexOf("?")!=-1)
            {
            　　var str = url.substr(url.indexOf("?")+1)　//去掉?号
               var　　strs = str.split("&");
                　　for(var i=0;i<strs.length;i++)
                　　{
                　　　 Request[strs[i ].split("=")[0]]=unescape(strs[ i].split("=")[1]);
                　　}
                }
        //alert(Request["id"])
        // console.log(Request)
        this.usertype=Request["type"];
        this.pageNo=Request["page"];
        if(this.pageNo=="" || this.pageNo==null){
            this.pageNo=0
        }
        this.getList()
    },
    methods:{
         closeexport(){
            this.showexport=false
            this.exportdata=null
            },
        putexport(){
            if(this.exportdata==null || this.exportdata==''){
                this.$message.error("请选择时间段")
                return
            }
            this.exporting=true
            //window.location.href=`http://192.168.16.184:8085/${apiUrl.exportWork}?createTime=${this.exportdata[0]}&endTime=${this.exportdata[1]}`
            axios.post("/api/"+apiurl.exportResumeList,{createTime:this.exportdata[0],endTime:this.exportdata[1]},{ responseType: 'blob' }).then(res=>{
                var blob = new Blob([res.data]);
                this.exporting=false
                var downloadElement = document.createElement('a');
                var href = window.URL.createObjectURL(blob); //创建下载的链接
                downloadElement.href = href;
                downloadElement.download = new Date().getTime() + '.xlsx'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
            })
            this.showexport=false
            this.exportdata=null
        },
        getList(){
            if (this.search.age!="" && this.search.age!=null) {
                const arr = this.search.age.split('-')
                this.search.ageStart = arr[0]
                this.search.ageEnd = arr[1]
            }
            // if(this.search.header){
            //     this.search.header=1
            // }else{
            //     this.search.header=null
            // }
            // if(this.search.authentication){
            //     this.search.authentication=1
            // }else{
            //     this.search.authentication=null
            // }
            // if(this.search.works){
            //     this.search.works=1
            // }else{
            //     this.search.works=null
            // }

            if(this.search.age==""){
                this.search.age=null
                this.search.ageStart =null
                this.search.ageEnd = null
            }
            if(this.search.industry==""){
                this.search.industry=null
            }
             if(this.search.education==""){
                this.search.education=null
            }
            if(this.search.salary==""){
                this.search.salary=null
            }
            if(this.search.hands==""){
                this.search.hands=null
            }
            if(this.search.companyId==""){
                this.search.companyId=null
            }
            if(this.search.gender==""){
                this.search.gender=null
            }
            this.loading=true
            _api.post(apiurl.queryResumeList,{
                keyWord:this.search.keyword,
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                salary:this.search.salary,
                gender:this.search.gender,
                industry:this.search.industry,
                education:this.search.education,
                workExperience:this.search.hands,
                age:this.search.age,
                ageStart:this.search.ageStart,
                ageEnd:this.search.ageEnd,
                companyId:this.search.companyId,
                userType:this.usertype,
                startTime:this.search.startTime,
                endTime:this.search.endTime,

            }).then(res=>{
                // console.log(res)
                this.loading=false
                if (res.code === 200) {
                    this.loading = false
                    this.queryAllResumeList = res.data.list
                    this.queryAllResumeList.forEach((item, index) => {
                        //item.tags = item.tag.split(',')
                    })
                    this.total = res.data.total
                    // console.log('queryAllResumeList', this.queryAllResumeList)
                }
            })
        },
        routeToresumeInfo(row) {
            // console.log('id', id)
            this.$router.push({
                path: '/resume/show',
                query: {
                    id: row.id,
                    type:this.usertype,
                    page:this.pageNo
                }
            })
        },
        getsearchdata(data){//获取搜索条件
            console.log(data)
            this.search=data
            this.pageNo=1
            this.getList()
        },
         handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            // console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>
<style scoped>
@import "../../assets/css/resume.css";
</style>
<style lang="scss" scoped>
    .user-info{
        width: 30%;
        margin-left: 60px;
        flex-direction: column;
        min-height: 60px;
        justify-content: space-between;
        .name{
            color: #555;
            font-size: 12px;
        }
        .tags-warpper{
            margin-top: 15px;
            .tags{
                .el-tag{
                    margin-right: 10px;
                    margin-bottom: 5px;
                }
            }
        }
    }
    .resume{
        max-width: 500px;
        .done{
            font-size: 12px;
            color: #828282;
            justify-content: flex-end;
            .progress{
                margin-left: 10px;
                margin-top: 2px;
                width: 100px;
                height: 10px;
                background: #e1e1e1;
                .progress-do{
                    height: 100%;
                    background: #ff704f;
                }
            }
        }
        .name{
            font-size: 16px;
            font-weight: 700;
            line-height: 46px;
            color: #282828;
            border-bottom: 1px solid #eee;
        }
        .hope-job{
            padding: 5px 0;
            border-bottom: 1px solid #eee;
            p{
                font-size: 12px;
                line-height: 20px;
                color: #747474;
            }
        }
        .job-list{
            padding: 5px 0;
            border-bottom: 1px solid #eee;
            .tips{
                color: #ff0522;
                font-weight: 700;
            }
            .conpany{
                padding-top: 10px;
                .el-tag{
                    margin: 5px 10px 5px 0;
                }
            }
        }
    }
    .position{
            width: 30%;
            justify-content: space-between;
            flex-direction: column;
            min-height: 60px;
            .position-name{
                font-size: 14px;
                color: #111;
                margin-top: 8px;
                .position-name-desc{
                    font-size: 12px;
                    color: #333;
                }
            }
            .position-time{
                font-size: 12px;
                color: #555;
                margin-top: 12px;

            }
        }
        .wage{
            width: 10%;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
            .wage-info{
                font-size: 12px;
                margin-top: 8px;
                color: #555;
            }
            .wage-number{
                margin-top: 7px;
                margin-top: 8px;
                letter-spacing: 2px;
                font-weight: 550;
            }
        }
        .download{
            width: 20%;
            box-sizing: border-box;
            text-align: right;
            padding-top: 8px;
            div{
                cursor: pointer;
                display: inline-block;
                margin-left:8px;
            }
            div.button{
                padding:8px 12px;
            }

            // .el-button{
            //     width: 40%;
            //     &:hover{
            //         background: $hover;
            //     }
            // }
        }
</style>